import React from 'react';
import AccountDefault from '../icons/AccountDefault.js';

export interface ProfileImageProps {
    src?: string;
    accountDefaultClassName?: string;
    profileImageClassName?: string;
}

export const ProfileImage = ({
    src,
    accountDefaultClassName,
    profileImageClassName,
}: ProfileImageProps) => {
    if (src) {
        return <img src={src} alt="profile" className={profileImageClassName} />;
    }

    return <AccountDefault className={accountDefaultClassName} />;
};
