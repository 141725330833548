import { ActivitySlug } from '../../../types/Activity.js';

export const convertActivitySlugToPlainText = (activitySlug: ActivitySlug) => {
    switch (activitySlug) {
        case 'pair-it':
            return 'Pair It';
        case 'all-in':
            return 'All In';
        case 'battle-royale':
            return 'Battle Royale';
        case 'partner-up':
            return 'Partner Up';
        case 'quick-write':
            return 'Quick Write';
        default:
            return activitySlug;
    }
};
