import React, { useState } from 'react';
import { captureException } from '@sentry/react';
import Tooltip from '@mui/material/Tooltip';
import styles from 'styles/atoms/CopyButton.scss';
import IconButton from '@mui/material/IconButton';
import Duplicate from '../icons/Duplicate.js';
import CopyLink from '../icons/CopyLink.js';
import Button from './Button.js';

interface CopyButtonProps {
    content: string;
    className?: string;
    type?: 'link' | 'duplicate';
    text?: string;
}

export const CopyButton = (props: CopyButtonProps) => {
    const { content, className = '', type = 'duplicate', text } = props;
    const [showTooltip, setShowTooltip] = useState(false);

    const copyText = () => {
        navigator.clipboard
            .writeText(content)
            .then(() => {
                setTimeout(() => {
                    setShowTooltip(false);
                }, 350);

                setShowTooltip(true);
            })
            .catch((err) => {
                captureException(err);
            });
    };

    return (
        <div className={className}>
            <Tooltip
                title="Copied!"
                open={showTooltip}
                placement="top"
                classes={{
                    tooltip: styles.tooltip,
                }}
            >
                {text ? (
                    <Button
                        kind="secondary"
                        onClick={copyText}
                        className={styles.button}
                        startIcon={<CopyLink className={styles.icon} />}
                    >
                        <p className={styles.text}>{text}</p>
                    </Button>
                ) : (
                    <IconButton onClick={copyText} className={styles.icon_button}>
                        {type === 'duplicate' && <Duplicate className={styles.icon} />}
                        {type === 'link' && <CopyLink className={styles.icon} />}
                    </IconButton>
                )}
            </Tooltip>
        </div>
    );
};
