import React from 'react';
import cn from 'classnames';
import MuiDialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import styles from 'styles/atoms/Dialog.scss';
import Close from '../icons/Close.js';
import { SlideTransition } from './SlideTransition.js';

export interface DialogInterface {
    children: React.ReactNode;
    open: boolean;
    onClose: () => void;
    closeButtonClassName?: string;
    className?: string;
    id?: string;
    keepMounted?: boolean;
}

export const Dialog = ({
    id,
    children,
    open,
    onClose,
    closeButtonClassName,
    className,
    keepMounted = false,
}: DialogInterface) => {
    return (
        <MuiDialog
            id={id}
            className={cn([styles.container, className])}
            open={open}
            onClose={onClose}
            TransitionComponent={SlideTransition}
            slotProps={{
                backdrop: {
                    className: styles.backdrop,
                },
            }}
            keepMounted={keepMounted}
        >
            <IconButton
                id="dialog-close-button"
                aria-label="close"
                onClick={onClose}
                className={cn([styles.close_button, closeButtonClassName])}
            >
                <Close className={styles.close_icon} />
            </IconButton>
            {children}
        </MuiDialog>
    );
};
