import React, { useState } from 'react';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

export const SlideTransition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    const [direction, setDirection] = useState<'up' | 'down'>('up');
    return (
        <Slide
            direction={direction}
            onEntered={() => setDirection('down')}
            onExited={() => setDirection('up')}
            ref={ref}
            {...props}
        />
    );
});
