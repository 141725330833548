import { captureException } from '@sentry/react';
import { Storage } from 'aws-amplify';

export const uploadProfileImage = async (file: File) => {
    try {
        return await Storage.put(`profile-image`, file, {
            level: 'private',
            contentType: file.type,
        });
    } catch (error) {
        captureException(error);
    }
};
