import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Close from '../icons/Close.js';
import SwipeHand from '../icons/SwipeHand.js';
import styles from '../../styles/atoms/BreadcrumbOverlay.scss';

interface BreadcrumbOverlayProps {
    // controls whether the overlay displays or not
    show?: boolean;
    // optional styles from the parent, by default this will only display at the xs screen size
    className?: string;
    // the contents of the overlay can either be a string or JSX element
    message: React.JSX.Element | string;
}

const BreadcrumbOverlay = ({ show, message, className }: BreadcrumbOverlayProps) => {
    const [showOverlay, setShowOverlay] = useState(true);

    useEffect(() => {
        if (show !== showOverlay) {
            setShowOverlay(show);
        }
    }, [show]);

    const onClose = () => setShowOverlay(false);

    return (
        <Backdrop
            className={cn(styles.overlay, className)}
            open={showOverlay}
            onClick={() => setShowOverlay(false)}
        >
            <IconButton
                className={styles.close_button}
                aria-label="close"
                onClick={onClose}
            >
                <Close />
            </IconButton>
            <div className={styles.content}>
                <SwipeHand className={styles.swipe_hand} />
                {message}
            </div>
        </Backdrop>
    );
};

export default BreadcrumbOverlay;
