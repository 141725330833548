import React from 'react';
import cn from 'classnames';
import styles from '../../styles/atoms/DisplayResponse.scss';
import ThreeDotLoader from './ThreeDotLoader.js';

interface DisplayResponseProps {
    response: string;
    title?: string;
    className?: string;
    isLoading?: boolean;
}

const DisplayResponse = (props: DisplayResponseProps) => {
    const { response, title, className, isLoading } = props;

    return (
        <div className={cn(className, styles.left_side)}>
            <div className={styles.left_side_wrapper}>
                <h1 className={styles.left_side_header}>{title}</h1>
                {isLoading ? (
                    <ThreeDotLoader />
                ) : (
                    <div className={styles.question_response}>
                        <div className={styles.response_text}>{response}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DisplayResponse;
