import confetti from 'canvas-confetti';

const rectangle = confetti.shapeFromPath({ path: 'M0 0H5V23H0V0Z' });
const triangle = confetti.shapeFromPath({
    path: 'M3.88249 0.597949L13.4513 9.8384L0.664408 13.505L3.88249 0.597949Z',
});
const star = confetti.shapeFromPath({
    path: 'M11.5 0L14.0819 7.9463H22.4371L15.6776 12.8574L18.2595 20.8037L11.5 15.8926L4.74047 20.8037L7.32238 12.8574L0.56285 7.9463H8.91809L11.5 0Z',
});

const shapesWithSettings = [
    {
        id: 'rectangle',
        shape: rectangle,
        color: '#F9EE60',
        scale: 2,
        count: 15,
    },
    {
        id: 'triangle',
        shape: triangle,
        color: '#FF87A4',
        scale: 1.5,
        count: 13,
    },
    {
        id: 'star',
        shape: star,
        color: '#29EFCC',
        scale: 3.5,
        count: 10,
    },
];

/**
 * This confetti animation shoots from the left and right of the page and falls down from top to bottom.
 * It is a full page of confetti, rather than a small burst.
 */

export const showFullPageConfetti = () => {
    shapesWithSettings.forEach((item) => {
        const sharedConfettiConfig = {
            spread: 150,
            particleCount: item.count,
            gravity: 1,
            startVelocity: 100,
            shapes: [item.shape],
            colors: [item.color],
            scalar: item.scale,
            ticks: 1000,
            disableForReducedMotion: true,
        };

        // Shoots from the left side
        confetti({
            origin: { y: 1, x: 0.3 },
            ...sharedConfettiConfig,
        });
        // Shoots from the right side
        confetti({
            origin: { y: 1, x: 0.7 },
            ...sharedConfettiConfig,
        });
    });
};
