import React, { useRef } from 'react';
import cn from 'classnames';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import styles from 'styles/_helpers/buildOnboardingScreenList.scss';
import { SubjectDropdown } from '../molecules/SubjectDropdown.js';
import { StateDropdown } from '../molecules/StateDropdown.js';
import { GradeChecklist } from '../molecules/GradeChecklist.js';
import { SubjectsList } from '../../../types/Room.js';
import { useScrollShadow } from '../hooks/animations/useScrollShadow.js';
import { CountryDropdown } from '../molecules/CountryDropdown.js';
import { avatars } from '../lib/splashScreenAvatars.js';
import Avatar from '../organisms/Avatar/index.js';
import Close from '../icons/Close.js';

interface OnboardingScreenListProps {
    teachingState: string;
    teachingCountry: string;
    grades: string[];
    subjects: [] | SubjectsList;
    preferredActivityDuration: number;
    setTeachingState: React.Dispatch<React.SetStateAction<string>>;
    setTeachingCountry: React.Dispatch<React.SetStateAction<string>>;
    setGrades: React.Dispatch<React.SetStateAction<string[]>>;
    setSubjects: React.Dispatch<React.SetStateAction<[] | SubjectsList>>;
    setPreferredActivityDuration: React.Dispatch<React.SetStateAction<number>>;
    handleSubmit: () => void;
}

const ScreenOne = ({
    teachingState,
    setTeachingState,
    teachingCountry,
    setTeachingCountry,
    grades,
    setGrades,
}) => {
    return (
        <>
            <div className={styles.header}>Welcome! To start...</div>
            <form className={styles.form}>
                <CountryDropdown
                    setTeachingCountry={setTeachingCountry}
                    teachingCountry={teachingCountry}
                />

                {teachingCountry === 'United States of America' && (
                    <StateDropdown
                        teachingState={teachingState}
                        setTeachingState={setTeachingState}
                    />
                )}

                <GradeChecklist
                    selectedGrades={grades}
                    onChange={(update) => setGrades(update)}
                />
            </form>
        </>
    );
};

const ScreenTwo = ({ grades, subjects, setSubjects }) => {
    const containerRef = useRef(null);

    const { topShadow, bottomShadow } = useScrollShadow(containerRef);

    return (
        <>
            <div className={styles.header}>Got it! Now...</div>
            <form className={styles.form}>
                <div
                    ref={containerRef}
                    style={{ height: '100%', overflow: 'auto', padding: 10 }}
                >
                    <div
                        className={cn({
                            [styles.top_shadow]: topShadow,
                        })}
                    />
                    <SubjectDropdown
                        subjects={subjects}
                        setSubjects={setSubjects}
                        gradeList={grades}
                        darkMode
                        bold
                    />
                    <div
                        className={cn({
                            [styles.bottom_shadow]: bottomShadow,
                        })}
                    />
                </div>
            </form>
        </>
    );
};

const ScreenThree = () => {
    return (
        <>
            <div className={styles.header}>Thanks! Now here&apos;s a bit about us.</div>
            <ul className={styles.list_styles}>
                <li>Short Answer is built for in-class writing practice</li>
                <li>
                    Students write responses, provide and receive feedback, then discuss
                    results
                </li>
                <li>Activities can take anywhere from 10 to 45 minutes</li>
            </ul>
        </>
    );
};

const ScreenFour = ({ preferredActivityDuration, setPreferredActivityDuration }) => {
    return (
        <>
            <div className={styles.slider_header}>
                Our in-classroom writing activities can take anywhere from 10-45 minutes.
                How long do you usually want yours to take?
            </div>
            <Slider
                className={styles.slider}
                aria-label="Activity time"
                defaultValue={15}
                value={preferredActivityDuration}
                onChange={(e, num: number) => setPreferredActivityDuration(num)}
                getAriaValueText={(num) => `${num}`}
                shiftStep={15}
                step={5}
                min={10}
                max={45}
            />
            <p className={styles.slider_value}>{preferredActivityDuration} mins</p>
        </>
    );
};

const ScreenFive = ({ preferredActivityDuration }) => {
    let durationContent = '';

    if (preferredActivityDuration <= 15) {
        durationContent =
            'To help your activities take 10-15 minutes, you may want to have students write their responses before class, then copy and paste them in.';
    } else if (preferredActivityDuration > 15 && preferredActivityDuration <= 30) {
        durationContent =
            'For your activities to take 20-30 minutes, students will need to be able to write a response in 5-10 minutes. You might also want to have students write their responses before class, then paste them in.';
    } else if (preferredActivityDuration > 30) {
        durationContent =
            'For your activities to take 30-45 minutes, students will need to be able to write a response to your question or prompt in 10-20 minutes.';
    }

    return (
        <>
            <h1 className={styles.header}>Understood!</h1>
            <p className={styles.paragraph}>{durationContent}</p>
        </>
    );
};

const ScreenSix = ({ handleSubmit }) => {
    const animationsRef = useRef();

    useGSAP(
        () => {
            const avatarIds = avatars.map((a) => `#Avatar_svg_${a.id}`);

            gsap.from('.h1_word', {
                rotation: '+=22',
                scale: 0,
                stagger: 0.1,
                ease: 'elastic.out',
                duration: 2,
            });
            gsap.from(avatarIds, {
                top: '-35%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                scale: 0,
                ease: 'power4.out',
                duration: 0.4,
                stagger: { each: 0.1, from: 'random' },
                delay: 0.5,
            });
            gsap.from('button', {
                scale: 0,
                ease: 'power4.out',
                duration: 0.7,
                delay: 0.7,
            });
        },
        { scope: animationsRef?.current },
    );

    return (
        <>
            <IconButton
                id="dialog-close-button"
                aria-label="close"
                onClick={() => handleSubmit()}
                className={styles.close_button}
            >
                <Close className={styles.close_icon} />
            </IconButton>
            <div className={styles.content_wrapper} ref={animationsRef}>
                <div className={styles.avatars}>
                    {avatars.map((avatar) => (
                        <Avatar
                            key={avatar.id}
                            {...avatar}
                            className={styles[`avatar_${avatar.id}`]}
                        />
                    ))}
                </div>

                <h1 id="h1" className={styles.h1}>
                    <div className="h1_word">Let&apos;s&nbsp;</div>
                    <div className="h1_word">create your&nbsp;</div>
                    <div className="h1_word">first&nbsp;</div>
                    <div className="h1_word">activity!&nbsp;</div>
                </h1>
            </div>
        </>
    );
};

export const buildOnboardingScreenList = ({
    teachingState,
    teachingCountry,
    grades,
    subjects,
    setTeachingState,
    setTeachingCountry,
    setGrades,
    setSubjects,
    preferredActivityDuration,
    setPreferredActivityDuration,
    handleSubmit,
}: OnboardingScreenListProps): React.JSX.Element[] => {
    const screenList: React.JSX.Element[] = [
        <ScreenOne
            teachingCountry={teachingCountry}
            setTeachingCountry={setTeachingCountry}
            teachingState={teachingState}
            setTeachingState={setTeachingState}
            grades={grades}
            setGrades={setGrades}
        />,
        <ScreenTwo grades={grades} subjects={subjects} setSubjects={setSubjects} />,
        <ScreenThree />,
        <ScreenFour
            preferredActivityDuration={preferredActivityDuration}
            setPreferredActivityDuration={setPreferredActivityDuration}
        />,
        <ScreenFive preferredActivityDuration={preferredActivityDuration} />,
        <ScreenSix handleSubmit={handleSubmit} />,
    ];

    return screenList;
};
