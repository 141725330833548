import { formatDistance, differenceInDays } from 'date-fns';

export const findDifferenceInDays = (futureDate: Date) => {
    const today = new Date();
    const daysRemaining = differenceInDays(futureDate, today);

    return Math.max(daysRemaining, 0);
};

/**
 * Format a timestamp into a string distance date
 */
export const formatDateDistance = (date: Date) => {
    const now = new Date();

    const result = formatDistance(date, now, {
        addSuffix: true,
    });

    return result;
};

export const isDateRecentlyAdded = (date: Date) => {
    const result = formatDateDistance(date);

    return result === 'less than a minute ago';
};
