import React, { useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import styles from 'styles/atoms/SearchBar.scss';
import TextInput from './TextInput.js';
import MagnifyingGlass from '../icons/MagnifyingGlass.js';
import Close from '../icons/Close.js';

export interface SearchBarProps {
    searchValue: string;
    onChange: (value: string) => void;
    className?: string;
}

const SearchBar = ({ searchValue, onChange, className }: SearchBarProps) => {
    const inputRef = useRef(null);
    return (
        <div className={className}>
            <TextInput
                ref={inputRef}
                value={searchValue}
                onChange={(e) => onChange(e.target.value)}
                variant="outlined"
                placeholder="Search"
                className={styles.input}
                startAdornment={<MagnifyingGlass />}
                endAdornment={
                    searchValue !== '' && (
                        <IconButton
                            id="dialog-close-button"
                            aria-label="close"
                            onClick={() => {
                                onChange('');
                                inputRef.current.focus();
                            }}
                            className={styles.close_button}
                        >
                            <Close className={styles.close_icon} />
                        </IconButton>
                    )
                }
            />
        </div>
    );
};

export default SearchBar;
