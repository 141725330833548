// Make sure file type is either image or pdf
export const isValidFileType = (file: File) => {
    const acceptedFileTypes = [
        'application/pdf',
        'image/png',
        'image/jpeg',
        'image/webp',
    ];

    return acceptedFileTypes.includes(file?.type);
};

/**
 * For now, students can only upload png or jpeg
 *
 * This is because the React-pdf library does not support webp
 * TODO: figure out how to support pdf and webp files
 *  */

export const isValidStudentFileType = (file: File) => {
    const acceptedFileTypes = ['image/png', 'image/jpeg'];

    return acceptedFileTypes.includes(file?.type);
};

// Make sure file size is less than maxFileSize in MB
export const isValidFileSize = (file: File, maxFileSize: number) => {
    return file.size <= maxFileSize * 1024 * 1024;
};

/**
 * Only allow png, jpeg or webp for profile image
 */

export const isValidProfileImageFileType = (file: File) => {
    const acceptedFileTypes = ['image/png', 'image/jpeg', 'image/webp'];

    return acceptedFileTypes.includes(file?.type);
};
