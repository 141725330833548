import { AccessLevel } from '../../../types/Attachment.js';

export const convertFileToAttachmentDetails = (
    file: File,
    accessLevel: AccessLevel,
    identityId: string,
) => {
    return {
        aws_identity: identityId,
        file_type: file.type,
        file_name: file.name,
        file_size: file.size,
        access_level: accessLevel,
    };
};
