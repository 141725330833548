import React from 'react';
import cn from 'classnames';
import styles from 'styles/atoms/ThreeDotLoader.scss';

const ThreeDotLoader = () => {
    return (
        <div className={styles.loader}>
            <div className={cn([styles.dot, styles.dot1])} />
            <div className={cn([styles.dot, styles.dot2])} />
            <div className={cn([styles.dot, styles.dot3])} />
        </div>
    );
};

export default ThreeDotLoader;
