/** Helpers to make sure user submitted password meets all AWS Cognito password reqs */
export const hasMinCharLength = (password: string) => password.length >= 8;
export const hasUppercase = (password: string) => /[A-Z]/.test(password);
export const hasLowercase = (password: string) => /[a-z]/.test(password);
export const hasNum = (password: string) => /[0-9]/.test(password);
export const hasSpecialChar = (password: string) =>
    // eslint-disable-next-line no-useless-escape
    /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password);

export const isValidPassword = (password: string) => {
    return (
        hasMinCharLength(password) &&
        hasUppercase(password) &&
        hasLowercase(password) &&
        hasNum(password) &&
        hasSpecialChar(password)
    );
};
