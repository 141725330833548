import React from 'react';

export interface BadWordsErrorProps {
    error?: string;
    className?: string;
}
export const BadWordsError = ({ error, className }: BadWordsErrorProps) => {
    const errorMsgSplits = error.split('/');
    const badwords = errorMsgSplits[1];

    return (
        <span className={className}>
            {errorMsgSplits[0]}
            <b>{badwords}</b>
            {errorMsgSplits[2]}{' '}
        </span>
    );
};
