import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import MuiButton from '@mui/material/Button';
import { ButtonProps as MuiButtonProps } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import styles from 'styles/atoms/Button.scss';

/** Available button kinds */
export type ButtonKind =
    | 'main'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'text'
    | 'danger';

/**
 * Props for Button, picked from MUI's ButtonProps
 */
export interface ButtonProps extends MuiButtonProps {
    /** The kind of button */
    kind?: ButtonKind;
    /** Adds a border radius of 100px to button */
    round?: boolean;
    /** Replaces button text with circular progress spinner */
    loading?: boolean;
    /** Path to navigate to. If value is set the underlying component is react-router Link */
    to?: string;
    /** Additional class name to apply to the button */
    className?: string;
    disabled?: boolean;
    children?: React.ReactNode;
}

/**
 * Used to map Button "kind" to a Material UI variant.
 *
 * MUI Variants: "contained", "text", "outline"
 */
export const variantMap: { [K in ButtonKind]: MuiButtonProps['variant'] } = {
    main: 'contained',
    primary: 'contained',
    secondary: 'outlined',
    tertiary: 'contained',
    danger: 'outlined',
    text: 'text',
};

/**
 * Used to map Button "kind" to an MUI button color.
 */
export const colorMap: { [K in ButtonKind]: MuiButtonProps['color'] } = {
    main: 'success',
    primary: 'primary',
    secondary: 'success',
    tertiary: 'secondary',
    danger: 'error',
    text: undefined,
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const {
        kind = 'primary',
        round,
        sx,
        onClick,
        loading,
        children,
        to,
        className,
        disabled,
        ...otherProps
    } = props;

    return (
        <MuiButton
            className={cn([
                styles.button_base,
                {
                    [styles.primary]: kind === 'primary',
                    [styles.secondary]: kind === 'secondary',
                    [styles.tertiary]: kind === 'tertiary',
                    [styles.text]: kind === 'text',
                    [styles.danger]: kind === 'danger',
                },
                className,
            ])}
            // @ts-ignore
            component={to ? Link : undefined}
            to={to}
            variant={variantMap[kind]}
            ref={ref}
            onClick={onClick}
            disabled={disabled || loading}
            {...otherProps}
        >
            {loading ? (
                <CircularProgress
                    className={cn(styles.loader, {
                        [styles.loader_blue]: kind === 'text',
                    })}
                    size={30}
                />
            ) : (
                children
            )}
        </MuiButton>
    );
});

export default Button;
