import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { WaitScreen } from '../templates/WaitScreen.js';
import { analytics } from '../../lib/analytics.js';
import { RoomProvider } from '../context/Room.js';
import { FeatureFlagProvider } from '../context/FeatureFlag.js';

const RequireAuth = () => {
    const [loadingUser, setLoadingUser] = useState(true);

    useEffect(() => {
        Auth.currentSession()
            .then(() => Auth.currentAuthenticatedUser())
            .then((currentUser) => {
                analytics.identify(currentUser?.username, {
                    email: currentUser?.attributes?.email,
                    name: currentUser?.attributes?.name,
                    grade: currentUser?.attributes?.['custom:grade_level'],
                    subject: currentUser?.attributes?.['custom:subject'],
                });
                setLoadingUser(false);
            });
    }, []);

    if (loadingUser) {
        return (
            <WaitScreen
                variant="indeterminate"
                loaderColor="yellow"
                message="Loading..."
            />
        );
    }

    return (
        <RoomProvider>
            <FeatureFlagProvider>
                <Outlet />
            </FeatureFlagProvider>
        </RoomProvider>
    );
};

export default RequireAuth;
