import gsap from 'gsap';

// Code taken from GSAP helpers https://gsap.com/docs/v3/HelperFunctions/helpers/tickGSAPWhileHidden
export function tickGSAPWhileHidden(value: boolean) {
    if (!value) {
        document.removeEventListener(
            'visibilitychange',
            tickGSAPWhileHidden.fn as EventListener,
        );
        clearInterval(tickGSAPWhileHidden.id);
        return;
    }

    const onChange = () => {
        clearInterval(tickGSAPWhileHidden.id);

        if (document.hidden) {
            gsap.ticker.lagSmoothing(0); // keep the time moving forward (don't adjust for lag)
            tickGSAPWhileHidden.id = setInterval(gsap.ticker.tick, 500);
        } else {
            gsap.ticker.lagSmoothing(500, 33); // restore lag smoothing
        }
    };

    document.addEventListener('visibilitychange', onChange);
    tickGSAPWhileHidden.fn = onChange;
    onChange(); // in case the document is currently hidden.
}

tickGSAPWhileHidden.fn = null as unknown as EventListener;
tickGSAPWhileHidden.id = null as unknown as ReturnType<typeof setInterval>;
