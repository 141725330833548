import { WritingScaffolds } from '../../../types/WritingScaffolds.js';

/**
 *
 * @param scaffold
 * @returns the display name of the scaffold (referred to as "Tools" in the app UI)
 */
export const convertScaffoldKeyToPlainText = (
    scaffold: keyof WritingScaffolds,
): string => {
    switch (scaffold) {
        case 'outline':
            return 'Outline';

        case 'sentence-stems':
            return 'Stems';

        case 'translate':
            return 'Translate';

        // no default
    }
};
