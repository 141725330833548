import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
import styles from 'styles/atoms/FileDropzoneInput.scss';
import validateFiles from '../_helpers/validateFiles.js';
import deleteAttachment from '../_helpers/deleteAttachment.js';
import Upload from '../icons/Upload.js';
import FileAttachmentThumbnail from '../molecules/FileAttachmentThumbnailV2.js';
import { PreviewFileModal } from '../molecules/modals/PreviewFileModal.js';
import Button from './Button.js';

export interface FileDropzoneInputProps {
    fileList: FileList;
    onDrop: (selectedFiles: FileList) => void;
    onError: (error: string) => void;
    isStudentFile?: boolean;
    maxFileCount?: number;
    maxFileSize?: number;
}

export const FileDropzoneInput = ({
    fileList,
    onDrop,
    onError,
    isStudentFile = false,
    maxFileCount = 2,
    maxFileSize = 2,
}: FileDropzoneInputProps) => {
    const [showFilePreview, setShowFilePreview] = useState<File | null>(null);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop: (selectedFiles: File[], rejections, e) => {
            e?.preventDefault();
            const newFiles = selectedFiles ? Array.from(selectedFiles) : [];
            const originalFiles = fileList ? Array.from(fileList) : [];

            const validatedFiles = validateFiles(newFiles, {
                existingFiles: originalFiles,
                onError,
                isStudentFile,
                maxFileCount,
                maxFileSize,
            });

            if (validatedFiles) {
                onDrop(validatedFiles);
            }
        },
        maxFiles: maxFileCount,
        noClick: true,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/webp': ['.webp'],
            'application/pdf': ['.pdf'],
        },
        preventDropOnDocument: true,
    });

    const handleDeleteAttachment = (file: File) => {
        const updatedAttachments = deleteAttachment(file, fileList);
        onDrop(updatedAttachments);
        onError('');
    };

    const fileListKeys = fileList && Object.keys(fileList);

    const showUploadFileOverlay = () => <div className={styles.overlay} />;
    const limitFilesMessage = `Limit ${maxFileCount} file${
        maxFileCount > 1 ? 's' : ''
    } per question`;
    const isMaxCountReached = !!fileList && fileList.length >= maxFileCount;

    return (
        <div className={styles.dropzone}>
            <div {...getRootProps()} className={styles.container}>
                <input {...getInputProps()} />
                <div
                    className={cn([
                        styles.content,
                        isMaxCountReached && styles.max_file_count,
                    ])}
                >
                    <div>
                        <Upload />
                    </div>
                    <div>
                        Drag and drop here or{' '}
                        <Button
                            className={styles.link_text}
                            kind="text"
                            onClick={() => {
                                open();
                                onError('');
                            }}
                            disabled={isMaxCountReached}
                        >
                            choose file
                        </Button>
                    </div>
                    <div>{limitFilesMessage}</div>
                </div>
                {fileListKeys?.length > 0 && (
                    <div className={styles.file_attachment_container}>
                        {fileListKeys?.map((fileKey) => {
                            const file = fileList[fileKey];

                            return (
                                <FileAttachmentThumbnail
                                    key={fileKey}
                                    className={styles.file_attachment}
                                    file={file}
                                    onDelete={handleDeleteAttachment}
                                    onClick={setShowFilePreview}
                                />
                            );
                        })}
                    </div>
                )}
                {isDragActive && !isMaxCountReached && showUploadFileOverlay()}
            </div>
            <div className={styles.dropzone_notes}>
                <div>Supported files: JPEG, PNG, WEBP, PDF </div>
                <div>Maximum Size: {maxFileSize}MB</div>
            </div>
            <PreviewFileModal
                fileList={fileList || {}}
                initialSelectedFile={showFilePreview}
                open={!!showFilePreview}
                onClose={() => setShowFilePreview(null)}
            />
        </div>
    );
};
