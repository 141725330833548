import React from 'react';
import cn from 'classnames';
import MUITooltip from '@mui/material/Tooltip';
import styles from 'styles/atoms/Tooltip.scss';
import { TransitionProps } from '@mui/material/transitions';

type Placement =
    | 'right'
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | 'top';

export interface TooltipProps {
    tooltipTitle: React.ReactNode;
    tooltipParent?: React.ReactElement<any, any>;
    expanded?: boolean;
    tooltipClassName?: string;
    tooltipArrowClassName?: string;
    placement?: Placement;
    transitionComponent?: React.JSXElementConstructor<
        TransitionProps & {
            children: React.ReactElement<any, any>;
        }
    >;
    transitionProps?: TransitionProps;
}

export const Tooltip = ({
    tooltipTitle,
    tooltipParent,
    expanded,
    tooltipClassName,
    tooltipArrowClassName,
    placement = 'right',
    transitionComponent = null,
    transitionProps = null,
}: TooltipProps) => {
    return expanded ? (
        tooltipParent
    ) : (
        <MUITooltip
            title={tooltipTitle}
            classes={{
                tooltip: cn([styles.tooltip, tooltipClassName]),
                arrow: cn([styles.tooltip_arrow, tooltipArrowClassName]),
            }}
            placement={placement}
            TransitionComponent={transitionComponent}
            TransitionProps={transitionProps}
            arrow
        >
            <div>{tooltipParent}</div>
        </MUITooltip>
    );
};
