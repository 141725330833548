import {
    isValidFileSize,
    isValidFileType,
    isValidProfileImageFileType,
    isValidStudentFileType,
} from './fileValidationHelpers.js';

interface FileValidationOptions {
    maxFileCount?: number;
    maxFileSize?: number;
    isStudentFile?: boolean;
    isProfileImg?: boolean;
    existingFiles?: File[];
    onError?: (err: string) => void;
}

/*
    This helper takes in the files to be validated along with some options.
    By default the maxFileCount will be set to 1 and the fileType check will
    be for teacher files. The fileSize is a number in MB; it will use
    2MB by default.
*/
const validateFiles = (
    newFiles: File[],
    options?: FileValidationOptions,
): FileList | undefined => {
    const {
        maxFileCount = 1,
        maxFileSize = 5,
        isStudentFile,
        isProfileImg,
        existingFiles,
        onError,
    } = options;
    const fileTypeIsValid = isStudentFile ? isValidStudentFileType : isValidFileType;
    const totalFiles = existingFiles?.length
        ? existingFiles.length + newFiles.length
        : newFiles.length;

    if (totalFiles > maxFileCount) {
        onError(
            `You can only upload ${maxFileCount} file${
                maxFileCount > 1 ? 's' : ''
            } per question`,
        );
        return undefined;
    }

    for (let i = 0; i < newFiles.length; i++) {
        const uploadedFile = newFiles[i];

        if (isProfileImg) {
            if (!isValidProfileImageFileType(uploadedFile)) {
                onError('Invalid file type. Please upload a JPEG, PNG, or WEBP file.');
                return undefined;
            }
        } else if (!fileTypeIsValid(uploadedFile)) {
            const fileNameSplit = uploadedFile?.name?.split('.');
            const extension = fileNameSplit?.[fileNameSplit.length - 1];

            onError(`File type${extension ? `: .${extension}` : ''} not supported`);
            return undefined;
        }

        if (!isValidFileSize(uploadedFile, maxFileSize)) {
            onError(`File size must be less than ${maxFileSize}MB`);
            return undefined;
        }
    }

    onError('');

    const fileList = new DataTransfer();
    const allFiles = existingFiles.concat(newFiles);

    allFiles.forEach((file) => fileList.items.add(file));
    return fileList.files;
};

export default validateFiles;
