export const getImageDimensions = (
    img: HTMLImageElement,
    maxWidth: number,
    maxHeight: number,
    percentFilled: number,
    maxAllowedHeight?: number,
) => {
    const aspectRatio = img.naturalWidth / img.naturalHeight; // Use naturalWidth and naturalHeight for the original dimensions

    // Ensure percentFilled does not exceed 100%
    // eslint-disable-next-line no-param-reassign
    percentFilled = Math.min(percentFilled, 1);

    let imgWidth: number;
    let imgHeight: number;

    if (aspectRatio > 1) {
        // Landscape
        imgWidth = maxWidth * percentFilled;
        imgHeight = imgWidth / aspectRatio;
    } else {
        // Portrait or square
        imgHeight = maxHeight * percentFilled;
        imgWidth = imgHeight * aspectRatio;

        // Ensure that the image width does not exceed maxWidth
        if (imgWidth > maxWidth) {
            imgWidth = maxWidth;
            imgHeight = imgWidth / aspectRatio;
        }
    }

    // If maxAllowedHeight is provided and the calculated height exceeds it, adjust the dimensions
    if (maxAllowedHeight && imgHeight > maxAllowedHeight) {
        imgHeight = maxAllowedHeight;
        imgWidth = imgHeight * aspectRatio;

        // Additionally ensure that the image width does not exceed maxWidth after adjusting for maxAllowedHeight
        if (imgWidth > maxWidth) {
            imgWidth = maxWidth;
            imgHeight = imgWidth / aspectRatio;
        }
    }

    // Center the image
    const left = (maxWidth - imgWidth) / 2;
    const top = (maxHeight - imgHeight) / 2;

    return {
        width: imgWidth,
        height: imgHeight,
        left,
        top,
    };
};
