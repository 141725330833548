import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from 'styles/atoms/ReadAloud.scss';
import IconButton from '@mui/material/IconButton';
import Speaker from '../icons/Speaker.js';

export const ReadAloud = ({
    content,
    className,
    lang,
}: {
    content: string;
    className?: string;
    lang?: string;
}) => {
    const [readText, setReadText] = useState(false);
    const [speechSynthesis, setSpeechSynthesis] = useState<SpeechSynthesis | null>(null);

    useEffect(() => {
        setSpeechSynthesis(window.speechSynthesis);
    }, []);

    useEffect(() => {
        if (speechSynthesis !== null) {
            speechSynthesis.cancel();
        }
    }, [lang]);

    const checkLangSupportedVoice = () => {
        const voices = speechSynthesis?.getVoices() ?? [];

        return voices.find((v) => v.lang.split('-')[0] === lang);
    };

    const read = () => {
        setReadText(true);

        if (readText) {
            speechSynthesis.pause();
            setReadText(false);
            return;
        }

        if (speechSynthesis.speaking) {
            speechSynthesis.resume();
            return;
        }

        const textUtterance = new SpeechSynthesisUtterance(content);

        const langSupportedVoice = checkLangSupportedVoice();

        if (langSupportedVoice) {
            textUtterance.voice = langSupportedVoice;
        }

        textUtterance.lang = lang ?? 'en-US';

        speechSynthesis.speak(textUtterance);

        textUtterance.onend = () => {
            setReadText(false);
        };
    };

    const showSpeaker =
        speechSynthesis !== null && checkLangSupportedVoice() !== undefined;

    return (
        <div className={className}>
            {showSpeaker && (
                <IconButton
                    title="Play content"
                    onClick={read}
                    className={cn([
                        styles.speaker_button,
                        {
                            [styles.speaker_button_active]: readText,
                        },
                    ])}
                >
                    <Speaker className={styles.speaker_icon} />
                </IconButton>
            )}
        </div>
    );
};
