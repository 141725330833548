import React, { forwardRef } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material';

export type TextInputProps = TextFieldProps & {
    /** Normally an IconButton, Icon, or String, will be wrapped in InputAdornment with position of 'start' */
    startAdornment?: React.ReactNode;
    /** Normally an IconButton, Icon, or String, will be wrapped in InputAdornment with position of 'end' */
    endAdornment?: React.ReactNode;
    multiline?: boolean;
    readOnly?: boolean;
    className?: string;
    variant?: 'filled' | 'outlined' | 'standard';
};

const TextInput = forwardRef((props: TextInputProps, ref) => {
    const {
        startAdornment: sa,
        endAdornment: ea,
        readOnly,
        multiline,
        variant,
        className,
        ...otherProps
    } = props;
    const startAdornment = sa && <InputAdornment position="start">{sa}</InputAdornment>;
    const endAdornment = ea && <InputAdornment position="end">{ea}</InputAdornment>;
    return (
        <TextField
            className={className}
            variant={variant ?? 'filled'}
            multiline={multiline}
            InputProps={{ startAdornment, endAdornment, readOnly }}
            inputRef={ref}
            {...otherProps}
        />
    );
});

export default TextInput;
