const deleteAttachment = (file: File, existingFiles: FileList) => {
    const dtFileList = new DataTransfer();

    Object.keys(existingFiles).forEach((key) => {
        const currentFile = existingFiles[key];

        if (currentFile.name !== file.name) {
            dtFileList.items.add(currentFile);
        }
    });

    return dtFileList.files;
};

export default deleteAttachment;
