import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material';
import cn from 'classnames';
import React, {
    ChangeEvent,
    Fragment,
    MouseEvent,
    useEffect,
    useRef,
    useState,
} from 'react';
import styles from 'styles/atoms/TextAreaInput.scss';
import Mic from '../icons/Mic.js';
import Paperclip from '../icons/Paperclip.js';
import RightArrowIcon from '../icons/RightArrowIcon.js';
import SquareRootSymbol from '../icons/SquareRootSymbol.js';
import FileAttachmentThumbnail from '../molecules/FileAttachmentThumbnailV2.js';
import { PreviewFileModal } from '../molecules/modals/PreviewFileModal.js';
import { DropzoneWrapper } from '../organisms/DropzoneWrapper.js';
import MathKeyboard from '../organisms/MathKeyboard.js';
import deleteAttachment from '../_helpers/deleteAttachment.js';
import { isSpeechRecognitionSupported } from '../_helpers/isSpeechRecognitionSupported.js';
import validateFiles from '../_helpers/validateFiles.js';
import Button from './Button.js';

export interface FileValidationOptions {
    /** A boolean determining which file types need to be validated */
    isStudentFile?: boolean;
    /** A number determining the file size limit for validation */
    maxFileSize?: number;
    /** A number determining the file count for validation */
    maxFileCount?: number;
}

export interface TextAreaInputProps
    extends Pick<
        TextFieldProps,
        | 'autoFocus'
        | 'disabled'
        | 'error'
        | 'fullWidth'
        | 'id'
        | 'name'
        | 'placeholder'
        | 'required'
        | 'onKeyUp'
        | 'inputProps'
        | 'type'
        | 'label'
        | 'onCopy'
        | 'onPaste'
        | 'onCut'
    > {
    /** Required now so the math doesn't overwrite the answer */
    defaultValue: string;
    /** Optional onChange handler */
    onChange?: (text: string) => void;
    /** Sets readonly to true in inputProps, also sets pointerEvents to 'none' */
    readOnly?: boolean;
    /** Optional className to apply styles to input */
    className?: string;
    /** Whether to show the math toolbar icon in the input */
    showMathToolbarIcon?: boolean;
    /** Whether to show the attachment toolbar icon in the input */
    showAttachmentIcon?: boolean;
    /** Whether to show the next button in the input */
    onClickNextButton?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    /** Error handler from the parent component */
    onError?: (err: string) => void;
    /** A callback to handle behavior on focus of the input */
    onClick?: (
        e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
        val: boolean,
    ) => void;
    /** Set state with files to upload */
    setFilesToUpload?: (filesToUpload: FileList) => void;
    /** An optional initial fileList value */
    fileList?: FileList;
    /** An optional default fileList value */
    existingFiles?: FileList;
    /** Options specifically for file validation within the TextAreaInput */
    fileValidationOptions?: FileValidationOptions;
    /** Lang code to pass to voice to text */
    voiceToTextLang?: string;
}

type InputChangeOptions = {
    event?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    transcript?: string;
};

const TextAreaInput = (props: TextAreaInputProps) => {
    const {
        className,
        disabled,
        readOnly,
        error,
        onError,
        onChange,
        onClick,
        defaultValue,
        showMathToolbarIcon,
        showAttachmentIcon = true,
        onClickNextButton,
        setFilesToUpload,
        fileList,
        existingFiles,
        voiceToTextLang,
        fileValidationOptions = {
            isStudentFile: false,
            maxFileCount: 2,
            maxFileSize: 2,
        },
        type,
        ...otherProps
    } = props;

    const inputRef = useRef(null);
    const paperclipAttachRef = useRef(null);
    const toolbarRef = useRef(null);

    const [text, setText] = useState('');
    const [cursorPosition, setCursorPosition] = useState(null);
    const [showMathKeyboard, setShowMathKeyboard] = useState(false);
    const [recordVoice, setRecordVoice] = useState(false);
    const [showFilePreview, setShowFilePreview] = useState<File | null>(null);

    useEffect(() => {
        if (existingFiles) {
            setFilesToUpload(existingFiles);
        }
    }, [existingFiles]);

    useEffect(() => {
        // After the state is updated, we need to move the cursor to the right position
        if (cursorPosition !== null && inputRef.current) {
            inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }
    }, [cursorPosition]);

    useEffect(() => {
        setText(defaultValue);
    }, [defaultValue]);

    const handleInputChange = (inputChangeOptions: InputChangeOptions) => {
        const { event, transcript } = inputChangeOptions;

        if (transcript) {
            setText(transcript);
            onChange(transcript);
        } else {
            setText(event.target.value);
            onChange(event.target.value);
            setCursorPosition(event.target.selectionStart || 0);
        }
    };

    const handleMathKeyClick = (event: MouseEvent<HTMLButtonElement>, char: string) => {
        event.preventDefault();
        const { selectionStart, selectionEnd } = inputRef.current;

        const start = selectionStart ?? 0;
        const end = selectionEnd ?? 0;

        setText((prev) => {
            const updatedText = prev.slice(0, start) + char + prev.slice(end);

            return updatedText;
        });

        setCursorPosition(start + char.length);
    };

    const browseFilesToAttach = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        onError('');
        paperclipAttachRef.current.click();
    };

    const handleDeleteAttachment = (file: File) => {
        const updatedAttachments = deleteAttachment(file, fileList);

        setFilesToUpload(updatedAttachments);
    };

    const handleFileValidation = (selectedFiles: FileList) => {
        const newFiles = selectedFiles ? Array.from(selectedFiles) : [];
        const originalFiles = fileList ? Array.from(fileList) : [];

        const validatedFiles = validateFiles(newFiles, {
            existingFiles: originalFiles,
            onError,
            isStudentFile: fileValidationOptions.isStudentFile,
            maxFileCount: fileValidationOptions.maxFileCount,
            maxFileSize: fileValidationOptions.maxFileSize,
        });

        if (validatedFiles) {
            setFilesToUpload(validatedFiles);
        }
    };

    const fileListKeys = fileList && Object.keys(fileList);
    const SpeechRecognitionClass = (window as any).webkitSpeechRecognition;
    const speechRecognitionObject =
        (SpeechRecognitionClass && new SpeechRecognitionClass()) ?? {};
    speechRecognitionObject.interimResults = true;
    speechRecognitionObject.continuous = true;
    speechRecognitionObject.lang = voiceToTextLang ?? 'en-US';
    const recorderRef = useRef(speechRecognitionObject);

    let silenceTimer = null; // Timer to track silence

    const record = () => {
        const recorder = recorderRef.current;

        let currentTranscript = ''; // Variable for ongoing transcription

        const stopListeningAfterSilence = () => {
            clearTimeout(silenceTimer);
            silenceTimer = setTimeout(() => {
                recorder.stop();
                setRecordVoice(false);
            }, 3500); // 3.5 seconds of silence triggers stop
        };

        recorder.onresult = (event) => {
            currentTranscript = ''; // Reset transcript for new input

            // eslint-disable-next-line no-restricted-syntax
            for (const result of event.results) {
                const { transcript } = result[0];
                currentTranscript += transcript;
            }

            const updatedText = text + currentTranscript;
            handleInputChange({ transcript: updatedText });

            setCursorPosition(updatedText.length);

            // Reset the silence timer because speech was detected
            stopListeningAfterSilence();
        };

        recorder.onerror = () => {
            setRecordVoice(false);
        };

        if (recordVoice === true) {
            recorder.stop();
            setRecordVoice(false);
        } else {
            setRecordVoice(true);
            recorder.start();
            stopListeningAfterSilence(); // Start the silence timer when recording starts
        }
    };

    const toolbarIcons = [
        {
            id: 'math-keyboard-button',
            icon: SquareRootSymbol,
            onButtonClick: () => setShowMathKeyboard((prev) => !prev),
            className: cn([
                styles.math_keyboard_button,
                {
                    [styles.green]: showMathKeyboard,
                },
            ]),
            showIcon: showMathToolbarIcon,
        },
        {
            id: 'paperclip-attach-button',
            icon: Paperclip,
            onButtonClick: browseFilesToAttach,
            showIcon: showAttachmentIcon,
        },
        {
            id: 'voice-to-text-button',
            icon: Mic,
            onButtonClick: record,
            className: cn([
                styles.mic_button,
                {
                    [styles.mic_active]: recordVoice,
                },
            ]),
            iconClassName: styles.mic_icon,
            showIcon: isSpeechRecognitionSupported(),
        },
    ];

    return (
        <div className={className}>
            <div className={cn([styles.container])}>
                <DropzoneWrapper
                    onDrop={handleFileValidation}
                    className={styles.dropzone}
                >
                    <TextField
                        className={styles.text_field}
                        inputRef={inputRef}
                        InputProps={{
                            readOnly,
                            className: styles.text_field_input,
                        }}
                        multiline
                        rows={5}
                        onChange={(e) => handleInputChange({ event: e })}
                        onClick={(e) => {
                            if (onClick) {
                                onClick(e, true);
                            }
                        }}
                        value={text}
                        disabled={disabled}
                        {...otherProps}
                    />
                </DropzoneWrapper>
                <div className={styles.toolbar} ref={toolbarRef}>
                    <div className={styles.toolbar_icons}>
                        {toolbarIcons.map(
                            ({ icon, showIcon, onButtonClick, id, ...item }) => {
                                if (!showIcon) {
                                    return;
                                }

                                const IconComponent = icon;
                                const isPaperclip = id === 'paperclip-attach-button';

                                return (
                                    <Fragment key={id}>
                                        <IconButton
                                            id={id}
                                            key={id}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                onButtonClick(e);
                                                inputRef.current.focus();
                                            }}
                                            className={item.className}
                                        >
                                            <IconComponent
                                                className={item?.iconClassName}
                                            />
                                        </IconButton>
                                        {isPaperclip && (
                                            <input
                                                id="file-upload-icon"
                                                key="file-upload-icon"
                                                type="file"
                                                ref={paperclipAttachRef}
                                                hidden
                                                multiple
                                                onChange={(e) =>
                                                    handleFileValidation(e.target.files)
                                                }
                                            />
                                        )}
                                    </Fragment>
                                );
                            },
                        )}
                    </div>
                    {onClickNextButton && (
                        <Button
                            kind="primary"
                            type="button"
                            disabled={!text}
                            endIcon={
                                <RightArrowIcon className={styles.right_arrow_icon} />
                            }
                            onClick={onClickNextButton}
                            className={styles.next_btn}
                        >
                            Next
                        </Button>
                    )}
                </div>
                {fileListKeys?.length > 0 && (
                    <div className={styles.file_attachment_container}>
                        {fileListKeys?.map((fileKey) => {
                            const file = fileList[fileKey];

                            return (
                                <FileAttachmentThumbnail
                                    key={fileKey}
                                    className={styles.file_attachment}
                                    file={file}
                                    onDelete={handleDeleteAttachment}
                                    onClick={setShowFilePreview}
                                />
                            );
                        })}
                    </div>
                )}
                <MathKeyboard
                    onMathKeyClick={handleMathKeyClick}
                    expanded={showMathKeyboard}
                    className={styles.math_keyboard}
                    onCollapse={() => setShowMathKeyboard(false)}
                    excludeClickRefs={[toolbarRef, inputRef]}
                />
                <PreviewFileModal
                    fileList={fileList || {}}
                    initialSelectedFile={showFilePreview}
                    open={!!showFilePreview}
                    onClose={() => setShowFilePreview(null)}
                />
            </div>
        </div>
    );
};

export default TextAreaInput;
