import { captureException } from '@sentry/react';
import { Storage } from 'aws-amplify';

export const uploadProtectedFilesToS3 = async (filesToUpload: FileList | {}) => {
    const fileKeys = Object.keys(filesToUpload);

    const uploadFiles = fileKeys.map(async (key) => {
        const file = filesToUpload[key];

        if (file) {
            try {
                return await Storage.put(file.name, file, {
                    level: 'protected',
                    contentType: file.type,
                });
            } catch (error) {
                captureException(error);
                throw new Error(error);
            }
        }

        return null;
    });

    await Promise.all(uploadFiles);
};
