import Slider from '@mui/material/Slider';
import { SliderProps } from '@mui/material/Slider/index.js';
import React from 'react';
import styles from 'styles/atoms/NumberSlider.scss';

export type NumberSliderProps = Pick<
    SliderProps,
    'min' | 'max' | 'step' | 'onChangeCommitted' | 'id' | 'defaultValue'
>;

const NumberSlider = (props: NumberSliderProps) => {
    const { min, max, step, onChangeCommitted, id, defaultValue } = props;

    const marks = [...Array((max + 1 - min) / step).keys()].map((i) => {
        const value = (i + 1) * step;
        return { value, label: `${value}` };
    });

    return (
        <Slider
            id={id}
            min={min}
            max={max}
            marks={marks}
            className={styles.slider}
            step={null}
            value={defaultValue}
            onChangeCommitted={onChangeCommitted}
        />
    );
};

NumberSlider.defaultProps = {
    min: 1,
    max: 5,
    step: 1,
};

export default NumberSlider;
