import React from 'react';
import styles from 'styles/atoms/EllipsisLoader.scss';

export interface EllipsisLoaderProps {
    // Text to display next to the ellipsis
    text: string;
    className?: string;
}

export const EllipsisLoader = ({ text, className }: EllipsisLoaderProps) => {
    return (
        <div className={className}>
            <p>
                {text}
                <span className={styles.dot} id={styles.dot1}>
                    .
                </span>
                <span className={styles.dot} id={styles.dot2}>
                    .
                </span>
                <span className={styles.dot} id={styles.dot3}>
                    .
                </span>
            </p>
        </div>
    );
};
