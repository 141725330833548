import React from 'react';
import cn from 'classnames';
import Link from '@mui/material/Link';
import styles from 'styles/atoms/LogoTextLink.scss';
import LogoTextIcon from '../icons/LogoTextIcon.js';

const LogoTextLink = ({
    href,
    linkClassName,
    iconClassName,
}: {
    href?: string;
    linkClassName?: string;
    iconClassName?: string;
}) => {
    return (
        <Link
            className={cn([styles.link, linkClassName])}
            href={href ?? 'https://myshortanswer.com'}
            target={!href && '_blank'}
            rel="noreferrer"
            aria-label="home"
        >
            <LogoTextIcon className={cn([styles.icon, iconClassName])} />
        </Link>
    );
};

export default LogoTextLink;
