import React, { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { captureException } from '@sentry/react';
import SvgIcon from '@mui/material/SvgIcon';
import Button from './components/atoms/Button.js';
import VerticalChatBubbles from './components/icons/svgs/VerticalChatBubbles.svg';
import styles from './styles/ErrorBoundaryFallback.scss';

export const ErrorBoundaryFallback = () => {
    const error = useRouteError();

    useEffect(() => {
        const isChunkLoadError =
            // @ts-ignore
            error?.name === 'ChunkLoadError' ||
            // @ts-ignore
            /Loading chunk [\d]+ failed/.test(error?.message || '');

        if (isChunkLoadError) {
            const hasReloaded = sessionStorage.getItem('chunkLoadErrorReloaded');

            if (!hasReloaded) {
                sessionStorage.setItem('chunkLoadErrorReloaded', 'true');
                window.location.reload();
            }

            return;
        }

        if (error) {
            captureException(error);
        }
    }, [error]);

    return (
        <div className={styles.container}>
            <div className={styles.content_container}>
                <div className={styles.text_button_container}>
                    <h1 className={styles.h1}>Uh oh!</h1>
                    <h2 className={styles.h2}>Something went wrong.</h2>
                    <Button kind="primary" href="/" className={styles.button}>
                        Go to Homepage
                    </Button>
                </div>
                <SvgIcon viewBox="0 0 251 284" className={styles.icon}>
                    <VerticalChatBubbles />
                </SvgIcon>
            </div>
        </div>
    );
};

export default ErrorBoundaryFallback;
