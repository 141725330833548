import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import QuestionMarkCircle from '../icons/QuestionMarkCircle.js';

export interface QuestionMarkTooltipProps {
    tooltipTitle: React.ReactNode;
    tooltipClassName?: string;
    questionMarkClassName?: string;
}

const QuestionMarkTooltip = ({
    tooltipTitle,
    tooltipClassName,
    questionMarkClassName,
}: QuestionMarkTooltipProps) => {
    return (
        <Tooltip
            title={tooltipTitle}
            classes={{
                tooltip: tooltipClassName,
            }}
            placement="top"
        >
            <div>
                <QuestionMarkCircle
                    className={questionMarkClassName}
                    style={{
                        fontSize: '1rem',
                    }}
                />
            </div>
        </Tooltip>
    );
};

export default QuestionMarkTooltip;
