import { AccessLevel, Attachment } from '../../../types/Attachment.js';
import { convertFileToAttachmentDetails } from './convertFileToAttachmentDetails.js';

// Convert a FileList object to an array of Attachment objects that can be sent to the server
export const convertFileListToAttachmentDetails = (
    filesToUpload: FileList | {},
    accessLevel: AccessLevel,
    identityId?: string,
): Array<Partial<Attachment>> => {
    const fileKeys = Object.keys(filesToUpload ?? {});
    return fileKeys.map((key) => {
        const file = filesToUpload[key];

        if (file) {
            return convertFileToAttachmentDetails(file, accessLevel, identityId);
        }

        return null;
    });
};
