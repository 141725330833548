import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';
import { useQueryClient } from 'react-query';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';
import styles from '../../styles/atoms/OnboardingOverlay.scss';
import Logo from '../icons/Logo.js';
import Button from './Button.js';
import { useUpdateRoom } from '../hooks/mutations/useUpdateRoom.js';
import { buildOnboardingScreenList } from '../_helpers/buildOnboardingScreenList.js';
import { Grade, SubjectsList, UserEditableRoom } from '../../../types/Room.js';
import { QueryKeys } from '../../../constants/queryKeys.js';

const OnboardingOverlay = ({ onClose }) => {
    const navigate = useNavigate();
    const updateRoom = useUpdateRoom();
    const queryClient = useQueryClient();

    const [teachingState, setTeachingState] = useState<string>('');
    const [teachingCountry, setTeachingCountry] = useState<string>('');
    const [grades, setGrades] = useState<Grade[]>([]);
    const [subjects, setSubjects] = useState<SubjectsList | []>([]);
    const [screenNumber, setScreenNumber] = useState(0);
    const [preferredActivityDuration, setPreferredActivityDuration] =
        useState<number>(15);
    const [loading, setLoading] = useState<boolean>(false);

    const updateScreenNumber = (modifier: number) =>
        setScreenNumber(screenNumber + modifier);

    const handleSubmit = async (onSuccess?: () => void) => {
        setLoading(true);

        const roomUpdate: UserEditableRoom = {
            grades_taught: grades,
            subjects_taught: subjects,
            // If the user is in the US, use the state, otherwise use the country
            state:
                teachingCountry === 'United States of America'
                    ? teachingState
                    : teachingCountry,
            preferred_activity_duration: preferredActivityDuration,
        };

        updateRoom.mutateAsync(
            {
                room: roomUpdate,
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(QueryKeys.room);

                    setLoading(false);
                    onSuccess?.();
                    onClose();
                },
                onError: (err) => {
                    setLoading(false);
                    captureException(err);
                },
            },
        );
    };

    const onUpdateGrades = (update: Grade[]) => {
        setGrades(update);
    };

    const onboardingScreensList = buildOnboardingScreenList({
        teachingState,
        teachingCountry,
        grades,
        subjects,
        setTeachingState,
        setTeachingCountry,
        setGrades: onUpdateGrades,
        setSubjects,
        preferredActivityDuration,
        setPreferredActivityDuration,
        handleSubmit,
    });

    const totalScreens = onboardingScreensList.length - 1;
    const progress = (screenNumber / totalScreens) * 100;

    const isFinalScreen = screenNumber === totalScreens;

    const handleNextScreen = () => updateScreenNumber(1);

    return (
        <Backdrop
            componentsProps={{
                root: {
                    'aria-hidden': null,
                },
            }}
            className={styles.container}
            open
        >
            <div className={styles.content}>
                <div className={styles.title}>
                    <div className={styles.onboard_title}>
                        <div className={styles.logo}>
                            <Logo />
                            Short Answer
                        </div>
                        {!isFinalScreen && (
                            <div className={styles.progress_bar}>
                                <LinearProgress variant="determinate" value={progress} />
                            </div>
                        )}
                    </div>
                </div>
                <Fade key={screenNumber} in timeout={750}>
                    <div className={styles.screen_container}>
                        {onboardingScreensList[screenNumber]}
                    </div>
                </Fade>
                {!isFinalScreen && (
                    <div className={styles.footer}>
                        {screenNumber > 0 && (
                            <Button
                                kind="secondary"
                                className={styles.back_button}
                                onClick={() => updateScreenNumber(-1)}
                                loading={loading}
                                disabled={screenNumber === 0}
                            >
                                Back
                            </Button>
                        )}
                        <Button
                            onClick={handleNextScreen}
                            className={styles.next_button}
                            loading={loading}
                            disabled={
                                (screenNumber === 0 &&
                                    (grades.length === 0 ||
                                        (teachingState === '' &&
                                            teachingCountry ===
                                                'United States of America') ||
                                        teachingCountry === '')) ||
                                (screenNumber === 1 &&
                                    (!Object.values(subjects).every(
                                        (s) => s.length !== 0,
                                    ) ||
                                        (Array.isArray(subjects) &&
                                            subjects?.length === 0)))
                            }
                        >
                            Next
                        </Button>
                    </div>
                )}
                {isFinalScreen && (
                    <div className={styles.splash_footer}>
                        <Button
                            kind="secondary"
                            className={styles.back_button}
                            onClick={() => {
                                const onSuccess = () => {
                                    navigate('/dashboard/discover');
                                };

                                handleSubmit(onSuccess);
                            }}
                        >
                            Select from pre-created
                        </Button>
                        <Button
                            className={styles.next_button}
                            onClick={() => {
                                const onSuccess = () => {
                                    navigate('/questions/wizard');
                                };

                                handleSubmit(onSuccess);
                            }}
                        >
                            Create my own
                        </Button>
                    </div>
                )}
            </div>
        </Backdrop>
    );
};

export default OnboardingOverlay;
