import { Storage } from 'aws-amplify';
import { captureException } from '@sentry/react';
import { getS3ObjectKey } from '../../../shared/getS3ObjectKey.js';

export const uploadPublicFilesToS3 = async (
    filesToUpload: FileList,
    participantId: string,
    questionId: string,
) => {
    const fileKeys = Object.keys(filesToUpload);

    const uploadFiles = fileKeys.map(async (key) => {
        const file = filesToUpload[key];

        const s3ObjectKey = getS3ObjectKey(
            { participant_id: participantId, file_name: file.name },
            questionId,
        );

        if (file) {
            try {
                return await Storage.put(s3ObjectKey, file, {
                    level: 'public',
                    contentType: file.type,
                });
            } catch (error) {
                captureException(error);
                throw new Error(error);
            }
        }

        return null;
    });

    await Promise.all(uploadFiles);
};
