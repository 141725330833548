import React, { useState } from 'react';
import cn from 'classnames';
import TextField, { FilledTextFieldProps } from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import styles from 'styles/atoms/ChipInput.scss';

/**
 * Inspired by https://github.com/TeamWertarbyte/material-ui-chip-input/blob/master/src/ChipInput.js
 * */

export interface ChipInputProps extends Omit<FilledTextFieldProps, 'variant'> {
    chips?: string[];
    onUpdate?: (chips: string[]) => void;
    className?: string;
    disabled?: boolean;
    placeholder: string;
    label?: string;
    addOnSpace?: boolean;
    // Method that can be used to validate the input
    validate?: (input: string) => boolean;
    errorText?: string;
}

const ChipInput = ({
    chips,
    onUpdate,
    className,
    disabled,
    label,
    placeholder,
    addOnSpace,
    validate,
    errorText,
}: ChipInputProps) => {
    const [inputTextValue, setInputTextValue] = useState('');
    const [elToDelete, setElToDelete] = useState(null);
    const [error, setError] = useState(false);

    const deleteChip = (chip: string) => {
        const filtered = chips.filter((c) => c !== chip);
        onUpdate?.(filtered);

        if (elToDelete) {
            elToDelete.nextSibling.focus();
            setElToDelete(null);
        }
    };

    const addChip = (value: string) => {
        const valueExists = chips.some((chip) => chip === value);

        if (valueExists) {
            setInputTextValue('');
            return;
        }

        if (value) {
            const update = [...chips, value];
            onUpdate?.(update);

            setInputTextValue('');
        }
    };

    const handleKeyPress = (e) => {
        const { code, target } = e;

        switch (code) {
            case 'Backspace':
                setError(false);

                if (!inputTextValue) {
                    // Focus logic for deletion
                    if (elToDelete) {
                        elToDelete.focus();
                    } else {
                        const { previousSibling } = target;
                        if (previousSibling) {
                            previousSibling.classList.add('Mui-focusVisible');
                            setElToDelete(previousSibling);
                        }
                    }
                }
                break;
            case 'Space':
                // Add chip on space if enabled and input has value
                if (addOnSpace && inputTextValue) {
                    if (validate && !validate(inputTextValue)) {
                        setError(true);
                        return;
                    }

                    setError(false);
                    addChip(target.value);
                }
                break;
            case 'Enter':
                e.preventDefault(); // Prevent textarea from capturing return

                if (validate && !validate(inputTextValue)) {
                    setError(true);
                    return;
                }

                setError(false);
                addChip(target.value);

                break;

            // no default
        }
    };

    const handleBlur = (e) => {
        if (e.target.value) {
            if (validate && !validate(inputTextValue)) {
                setError(true);
                return;
            }

            setError(false);
            addChip(e.target.value);
        }
    };

    const handleChange = (e) => {
        if (elToDelete) {
            elToDelete.classList.remove('Mui-focusVisible');
            setElToDelete(null);
        }

        setInputTextValue(e.target.value);
    };

    return (
        <TextField
            label={label}
            className={cn([styles.text_field, className])}
            InputProps={{
                className: error ? styles.error : undefined,
                startAdornment: chips?.map((chip) => (
                    <Chip
                        id={chip}
                        key={chip}
                        label={chip}
                        onDelete={() => {
                            const chipToDelete = chips.find((c) => c === chip);
                            deleteChip(chipToDelete);
                        }}
                        className={styles.chip}
                    />
                )),
            }}
            placeholder={placeholder}
            multiline
            autoFocus
            helperText={errorText && error ? errorText : undefined}
            error={error}
            disabled={disabled}
            variant="standard"
            value={inputTextValue}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            onBlur={handleBlur}
            minRows={1}
        />
    );
};

export default ChipInput;
