import { captureException } from '@sentry/react';
import { Storage } from 'aws-amplify';
import { SharedQuestionAttachment } from '../../../types/SharedQuestion.js';

const isNonIso88591Error = (error: any) => {
    return (
        error?.message?.includes('String contains non ISO-8859-1 code point') ||
        error?.toString()?.includes('String contains non ISO-8859-1 code point')
    );
};

export const copyProtectedFilesInS3 = async (
    sourceAttachments: SharedQuestionAttachment[],
    destinationIdentityId: string,
) => {
    const copyFiles = sourceAttachments.map(async (attachment) => {
        const src = {
            key: attachment.file_name,
            level: attachment.access_level,
            identityId: attachment.source_aws_identity,
        };

        const dest = {
            key: attachment.file_name,
            level: attachment.access_level,
            identityId: destinationIdentityId,
        };

        try {
            // For most cases, this should work for copying files from one user to another
            return await Storage.copy(src, dest);
        } catch (error) {
            // This is necessary because the Storage.copy method does not handle non-ISO-8859-1 characters
            // Meaning this was failing if a user took a screenshot on Mac and uploaded a file with a name like: Screen Shot 2019-01-31 at 8.45.12 AM.png
            if (isNonIso88591Error(error)) {
                try {
                    const file = await Storage.get(attachment.file_name, {
                        level: attachment.access_level,
                        download: true,
                        identityId: attachment?.source_aws_identity,
                        cacheControl: 'max-age=3600',
                    });

                    // @ts-ignore
                    const blob = await file.Body?.blob?.();

                    await Storage.put(dest.key, blob, {
                        level: attachment.access_level,
                        contentType: attachment.file_type,
                    });

                    return true;
                } catch (fallbackError) {
                    captureException(fallbackError);
                    throw new Error(fallbackError);
                }
            } else {
                captureException(error);
                throw new Error(error);
            }
        }
    });

    await Promise.all(copyFiles);
};
