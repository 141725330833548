import React from 'react';
import styles from 'styles/atoms/MagicWandLoader.scss';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import MagicWand from '../icons/MagicWand.js';

export interface MagicWandLoaderProps {
    /**
     * This value is necessary because the .star and .circle classes are set on the svg
     * without the parentClassName if the svg is used in multiple places, the animation will
     * be applied to all of them and the stagger effect will be applied across all stars instead of
     * the ones nested under the same parent. This can either be a class or an id.
     */
    parentElement?: string;
}

export const MagicWandLoader = ({ parentElement }) => {
    useGSAP(() => {
        if (!parentElement) {
            return;
        }

        gsap.to(`#wand`, {
            duration: 3,
            ease: 'easeInOut',
            motionPath: {
                path: [
                    { x: 0, y: 0 },
                    { x: 10, y: 20 },
                    { x: 20, y: 0 },
                    { x: 0, y: 0 },
                ],
            },
            repeat: -1, // Infinite loop
        });

        // Fade in each star
        gsap.fromTo(
            `${parentElement} .star`,
            { opacity: 0 },
            {
                opacity: 1,
                stagger: 0.5,
                repeat: -1,
                ease: 'easeInOut',
            },
        );

        gsap.fromTo(
            `${parentElement} .circle`,
            { opacity: 0 },
            {
                opacity: 1,
                stagger: 0.3,
                repeat: -1,
                ease: 'easeInOut',
                delay: 2,
            },
        );
    }, [parentElement]);

    return (
        <div className={styles.container}>
            <MagicWand className={styles.icon} />
            <p className={styles.p}>Just a moment...AI magic in progress!</p>
        </div>
    );
};

export default MagicWandLoader;
