import React from 'react';
import cn from 'classnames';
import styles from 'styles/atoms/IconLoader.scss';
import { EllipsisLoader } from './EllipsisLoader.js';
import LogoIcon from '../icons/Logo.js';

export const IconLoader = ({ className }: { className?: string }) => {
    return (
        <div className={cn([styles.container, className])}>
            <LogoIcon className={styles.icon} />
            <EllipsisLoader text="Loading" className={styles.text} />
        </div>
    );
};
